var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('UserListPickerModal',{attrs:{"fromAgentPicker":true,"emitOnAction":true,"queryOverride":{
    role__in: [
      'agent',
      'agencyAdmin',
      'subAdmin',
      'underwriting',
      'claims',
      'billing',
      'dataEntry'
    ],
    agentCode: _vm.agentCode
      ? _vm.agentCode
      : _vm.$getCurrentUser && _vm.$getCurrentUser.agentCode
      ? _vm.$getCurrentUser.agentCode
      : '',
    status: 'active',
    deleted: false
  }},on:{"tableRowClicked":function($event){return _vm.$emit('tableRowClicked', $event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }
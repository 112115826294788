
import Vue from "vue";

export default Vue.extend({
  props: {
    agentCode: {
      type: String,
      required: false
    }
  },
  components: {
    UserListPickerModal: () =>
      import("@/components/User/ListUsers/UserListPickerModal.vue")
  },
  name: "agent-picker-modal"
});
